<script lang="ts">
    import type {
        LandingPageQuery,
        MorePostsAndSubmissionsQuery,
        PostTeaserFragment,
        SubmissionTeaserFragment,
    } from '$gen/graphql';
    import BigInseratGrid from '$lib/ads/BigInseratGrid.svelte';
    import LandingHero from '$lib/components/LandingHero.svelte';
    import NewsSlider from '$lib/components/carousel-news-slider/NewsSlider.svelte';
    import PostTeaser from '$lib/components/PostTeaser.svelte';
    import SmallInserat from '$lib/ads/SmallInserat.svelte';
    import { mergePostAndSubmissionsByDate, SubmissionsMerge } from '$lib/post-and-submission-merge';
    import { isPostTeaserFragment, isSubmissionTeaserFragment } from '$lib/types/type-predicates';
    import { chunk } from 'lodash-es';
    import OverlayVideos from '$lib/components/OverlayVideos.svelte';
    import { ads } from '$lib/stores';
    import { COOKIE_KEY_PLAY_OVERLAY_KUP, landingPagePostFetchLimit } from '$lib/constants';
    import NewsletterModal from '$lib/components/NewsletterModal.svelte';
    import { localStore } from '$lib/privacy-banner/localStore';
    import { browser, dev } from '$app/env';
    import MegaInserat from '$lib/ads/MegaInserat.svelte';
    import SeoMetaTags from '$lib/components/SeoMetaTags.svelte';
    import { routes } from '$lib/routes';
    import { throttle } from 'lodash-es';
    import BigInseratGridTwoCols from '$lib/ads/BigInseratGridTwoCols.svelte';

    export let query: LandingPageQuery;
    export let playOverlayVideos: PlayOverlayVideos;

    let offset = landingPagePostFetchLimit;
    let extraPosts: PostTeaserFragment[] = [];
    let extraSubmissions: SubmissionTeaserFragment[] = [];

    $: news = query && query.news ? (query.news as NewsPosts) : [];
    $: posts = [].concat(query?.posts.filter(isPostTeaserFragment), extraPosts);
    $: submissions = [].concat(
        query?.submissions.filter(isSubmissionTeaserFragment),
        extraSubmissions
    );
    $: mixedPosts = posts && submissions ? SubmissionsMerge(submissions) : [];
    $: overlayVideos = false;
    $: seo =
        query && query.content.__typename === 'landingPage_landingPage_Entry'
            ? query.content.seo
            : null;
    $: maxMixedPostsCount = query.postCount + query.exhibitionCount || 99999;
    $: bigAdsOnlyVisibleOnLgScreens = $ads?.big.filter((ad, index) => (index + 1) % 3 === 0);

    async function fetchMorePosts() {
        if (dev) console.log(`Fetch more post: `);
        const resp = await fetch(routes.api_loadMorePosts(offset));
        const json = await resp.json();
        const query: MorePostsAndSubmissionsQuery = json.query;

        if (query) {
            extraPosts = extraPosts.concat(query.posts.filter(isPostTeaserFragment));
            extraSubmissions = extraSubmissions.concat(
                query.submissions.filter(isSubmissionTeaserFragment)
            );
            offset += landingPagePostFetchLimit;
        }
    }

    const fetchMorePostsThrottled = throttle(fetchMorePosts, 2000);
</script>

<SeoMetaTags {seo} />

{#if overlayVideos && playOverlayVideos?.modeKUP === 'play'}
    <OverlayVideos
        STORAGE_KEY={COOKIE_KEY_PLAY_OVERLAY_KUP}
        videos={overlayVideos.kubaparisVideos[0]}
    />
{/if}

{#if query}
    {#if $ads && $ads.mega.length > 0}
        <MegaInserat ad={$ads.mega[0]} />
    {/if}
    {#if query?.content?.__typename === 'landingPage_landingPage_Entry'}
        <LandingHero content={query.content.landingHero}/>
    {/if}

    <!--  
    # Landing Page Grid:
    its not beautiful and sadly mostly hardcoded.
   
    Breakpoints:
    xl: 3 columns
    lg: 2 columns
    sm/md: 1 column

    Small ads are shown in the aside from breakpoint "md" on up, on smaller screens the small ads are inlined.
    -->
    <div class="mt-5 mb-5 grid auto-rows-min grid-cols-1 gap-5 lg:grid-cols-2 xl:grid-cols-3">
        <BigInseratGrid ads={$ads.big.slice(0, 3)} />
        {#if mixedPosts.length > 0}
            <div class="col-span-1 lg:col-span-2 xl:col-span-3">
                <PostTeaser post={mixedPosts[0]} />
            </div>
        {/if}

        <!-- News 1: Display the first three news here. -->
        {#if news.slice(0, 3).length > 0}
            <NewsSlider slides={news.slice(0, 3)} />
        {/if}

        <!-- Small ad-grid (only on "sm" screens) -->
        <div class="inserat-box col-span-full -mb-5 h-5 text-center text-xs md:hidden">
            Advertisement
        </div>
        <div class="grid grid-cols-4 grid-rows-2 gap-5 md:hidden">
            {#each $ads.small as ad}
                <SmallInserat {ad} />
            {/each}
        </div>

        <!-- Post 2: Display post nr 2, 3, 4  -->
        {#each mixedPosts.slice(1, 4) as post, i}
            {#if i === 2}
                <!-- To avoid gabs make the last post in the row of three on lg size span 2. -->
                <div class="col-span-1 lg:col-span-2 xl:col-span-1">
                    <PostTeaser {post} />
                </div>
            {:else}
                <div class="col-span-1">
                    <PostTeaser {post} />
                </div>
            {/if}
        {/each}
        {#each new Array(3 - mixedPosts.slice(1, 4).length) as _}
            <!-- This loop runs only if there previous loop had less than 3 items (only the case if there are less than 4 total posts.)
            If so, add some dummy elements to fill the empty gird cells to stop the adds below from jumping one row higher.
            -->
            <div />
        {/each}

        <!-- Big ads 3:   -->
        <BigInseratGrid ads={$ads.big.slice(3, 6)} />

        <!-- Display two post teasers in a special formation. -->
        <PostTeaser post={mixedPosts[4]} />
        <div class="col-span-1 xl:col-span-2">
            <PostTeaser post={mixedPosts[5]} />
        </div>

        <!-- News 2: Display all the other news here. -->
        {#if news.slice(3).length > 0}
            <NewsSlider slides={news.slice(3)} />
        {/if}

        <!--  If there are any big adds left display them all here -->
        {#each chunk($ads.big.slice(6), 3) as adTriplet}
            <BigInseratGrid ads={adTriplet} />
        {/each}

        <!-- on:lg (two col layout): display all the third hidden ads from the BigInseratGrid here. -->
        {#each chunk(bigAdsOnlyVisibleOnLgScreens, 2) as adPair, index}
            <BigInseratGridTwoCols {index} ads={adPair} />
        {/each}

        <!-- Display the rest of the posts here -->
        {#each mixedPosts.slice(6) as post, i}
            <div class="col-span-1 row-span-1">

                <PostTeaser {post} />
            </div>
        {/each}
    </div>
    {#if maxMixedPostsCount > mixedPosts.length}

            <button class="newbutton cl" on:click={fetchMorePostsThrottled}>Load more</button>

    {/if}
{/if}

<NewsletterModal open={browser && $localStore.showNewsletterModal} />


