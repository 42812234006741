<script lang="ts">
    export let content: LandingHeroType;

    let fontweightmax = false;
    let fontweight = 200;
    // setInterval(randomizeText, 1000);
    import { onMount } from 'svelte';
    onMount( () => {
        setInterval(randomizeText, 5000);
    });
    function randomizeText() {
        console.log('randomize!')
        if(fontweightmax) {
            fontweight = 200
        } else {
            fontweight = 700
        }
        fontweightmax = !fontweightmax
    }
</script>




{#if content}

    <section class="relative grid place-items-center">
        {#each content as hero}
 
          {#each hero.background as background}
                {#if background.__typename === 'background_image_BlockType'}


                <a href={background.InternalLandingLink}>
                    {#each background.image as img}
                        <img
                            class="h-full dark:hidden"
                            src={img.url}
                            alt={img.title}
                            width={img.width}
                            height={img.height}
                            srcset={img.srcset}
                        />
                    {/each}
                    {#each background.imageDarkMode as img}
                        <img
                            class="hidden h-full object-cover dark:block"
                            src={img.url}
                            alt={img.title}
                            width={img.width}
                            height={img.height}
                            srcset={img.srcset}
                        />
                    {/each}
                </a>
                {:else if background.__typename === 'background_video_BlockType'}
                    {#each background.video as video}


                        {#if video.embeddedAsset}
                            {@html video.embeddedAsset.html}
                        {:else if video.__typename === 'assets_Asset'}
                            <video
                                src={video.url}
                                width={video.width}

                                height={video.height}
                                muted
                                autoplay
                            >
                                <track kind="captions" />
                            </video>
                        {/if}
                    {/each}
                {/if}
            {/each}

            <div class="absolute pe-none top-0 bottom-0 right-0 left-0 grid select-none lol place-content-center">
                <h1
                    class="relative top-[0.8vw] text-[13.2vw] uppercase md:text-[11vw] lg:text-[11.9vw] xl:text-[12.1vw] 2xl:text-[12.5vw]"
                    id="logo-variable-font"
                    style="font-weight: {fontweight};"
                >
                    kubaparis
                </h1>
            </div>
        {/each}

    
       

    </section>
{/if}

<style>
    section :global(iframe) {
        width: 100%;
        object-fit: cover;
    }

    section h1 {
        --x: 0.04em;
        text-shadow: var(--x) var(--x) 0.03em rgba(127, 127, 127, 0.806);
    }
   
    .pe-none,
    .pe-none
     {
        pointer-events: none;
    }
    
    #logo-variable-font {
        font-weight: 550;
        font-family: ABCDiatype Variable;
        transition: font-weight 5s linear;
        transform: translateY(-1.1rem);
    }
</style>
